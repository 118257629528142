import { Option } from "../models/option";
import { Step } from "../models/step";
import { SubOption } from "../models/suboption";
import { Steps } from "../utils/steps";
import { TemplateType } from "../utils/templateType";

export class CalculatorService {
  getSteps(): Step[] {
    return [
      {
        number: Steps.Toestellen,
        title: "Toestellen",
        description: "Voor welke toestellen moet de app geoptimaliseerd worden?",
        icon: 'Toestellen.svg',
        options: this.getDeviceTypes(),
        field: 'deviceTypes',
        multipleOptions: true,
        templateType: TemplateType.SimpleSelector,
      },
      {
        number: Steps.Type,
        title: "Type",
        description: "Via welke distributiekanalen moet de app zijn weg naar de gebruikers vinden?",
        icon: 'Type.svg',
        options: this.getAppTypes(),
        multipleOptions: true,
        field: 'appType',
        templateType: TemplateType.SimpleSelector,
      },
      {
        number: Steps.Design,
        title: "Design",
        description: "Welke afwerkingsgraad moet de app hebben? Het maken en ontwikkelen van een ontwerp op maat is erg arbeidsintensief en kan jouw app 50% (fancy) tot 80% (epic) duurder maken.",
        icon: 'Design.svg',
        options: this.getDesignTypes(),
        multipleOptions: false,
        field: 'designType',
        templateType: TemplateType.SimpleSelector,
      },
      {
        number: Steps.Functies,
        title: "Functies",
        description: "Welke functies moet de app bevatten?",
        icon: 'Functies.svg',
        options: this.getFunctions(),
        multipleOptions: true,
        field: 'functions',
        templateType: TemplateType.ListSelector,
      },
      {
        number: Steps.Integraties,
        title: "Integraties",
        description: "Met hoeveel verschillende externe platformen moet er data uitgewisseld worden?",
        icon: 'Integraties.svg',
        options: this.getIntegrationTypes(),
        multipleOptions: false,
        field: 'integrationType',
        templateType: TemplateType.SimpleSelector,
      },
      {
        number: Steps.Offline,
        title: "Offline",
        description: "Moet de app ook werken als er geen internet - verbinding is? Een app die volledig offline moet kunnen werken kan tot 50% duurder zijn dan een app die niet offline moet kunnen werken.",
        icon: 'Offline-online.svg',
        options: this.getOfflineTypes(),
        multipleOptions: false,
        field: 'offlineType',
        templateType: TemplateType.SimpleSelector,
      },
      {
        number: Steps.Notificaties,
        title: "Notificaties",
        description: "Moet de app notificaties versturen bij bepaalde gebeurtenissen?",
        icon: 'Notifications.svg',
        options: this.getNotificationTypes(),
        multipleOptions: true,
        field: 'notificationType',
        templateType: TemplateType.SimpleSelector,
      },
      {
        number: Steps.Gegevens,
        title: "Gegevens",
        description: "Vul hieronder uw gegevens in en u krijgt de schatting van onze calculator meteen in uw mailbox. Heb je nog vragen of wil je meer informatie? Neem dan gerust contact met ons op via onze website: <a href='https://www.codecraft.be/contact' target='_blank'>codecraft.be/contact</a>.",
        icon: 'Gegevens.svg',
        options: this.getContactReasons(),
        multipleOptions: false,
        field: 'contactReason',
        templateType: TemplateType.Form,
      }
    ]
  }

  getPreviousSteps(currentStep: number): Step[] {
    return this.getSteps().filter((e: Step) => e.number < currentStep) ?? [];
  }

  getCurrentStep(currentStep: number): Step {
    return this.getSteps().find((e: Step) => e.number === currentStep) ?? new Step();
  }

  getPreviousStep(currentStep: number): Step | null {
    return this.getSteps().find((e: Step) => e.number === currentStep - 1) ?? null;
  }

  getNextStep(currentStep: number): Step | null {
    return this.getSteps().find((e: Step) => e.number === currentStep + 1) ?? null;
  }

  getDeviceTypes(): Option[] {
    return [
      {
        id: 1,
        title: "Smartphone",
        description: "Geoptimaliseerd voor weergave op een smartphone.",
        icon: "smartphone-icon.svg",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 2,
        title: "Tablet",
        description: "Geoptimaliseerd voor weergave op een tablet.",
        icon: "desktop-icon.svg",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 3,
        title: "Desktop",
        description: "Geoptimaliseerd voor weergave op een desktop.",
        icon: "tablet-icon.svg",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
    ];
  }

  getAppTypes(): Option[] {
    return [
      {
        id: 1,
        title: "Via de browser",
        description: "Via een url of via Google zoeken",
        hours: 0,
        icon: "web-app.svg",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 2,
        title: "Via de App Store",
        description: "App via de App Store van Apple",
        hours: 12,
        icon: "App_Store.svg",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 3,
        title: "Via de Play Store",
        description: "App via de Play Store van Google",
        hours: 12,
        icon: "logo-google-play.svg",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
    ];
  }

  getDesignTypes(): Option[] {
    return [
      {
        id: 1,
        title: "Basic",
        description: "De app moet er gewoon netjes uitzien en gebruiksvriendelijk zijn.",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Design.svg',
        coefficient: 1,
      },
      {
        id: 2,
        title: "Fancy",
        description: "Het mag iets meer zijn, de app moet een mooi en uniek design hebben.",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Design.svg',
        coefficient: 1.5,
      },
      {
        id: 3,
        title: "Epic",
        description: "De app moet er echt geweldig uit zien, gebruikers moeten een 'wow-effect' hebben.",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Design.svg',
        coefficient: 1.8,
      },
    ];
  }

  getFunctions(): Option[] {
    return [
      {
        id: 1,
        title: "Login",
        description: "De app moet (gedeeltelijk) beveiligd zijn met een login.",
        hours: 0,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 1),
      },
      {
        id: 3,
        title: "Gebruikersprofielen",
        description: "Hoeveel verschillende soorten gebruikers zullen gebruik maken van de app?",
        hours: 0,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 3),
      },
      {
        id: 2,
        title: "Gebruikersbeheer",
        description: "De beheerder kan zelf andere gebruikers toevoegen aan de app.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 2),
      },

      {
        id: 4,
        title: "Nieuwsberichten",
        description: "De app bevat nieuwsberichten. Nieuwberichten kunnen worden beheerd en getoond.",
        hours: 20,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 4),
      },
      {
        id: 5,
        title: "Projecten",
        description: "In de app kunnen projecten beheerd worden. Denk daarbij aan het bijhouden van een status van een project en het bijhouden van documenten rond een project.",
        hours: 20,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 5),
      },
      {
        id: 51,
        title: "Tijdsregistratie",
        description: "De verschillende gebruikers in de app kunnen tijdsregistraties doen, gekoppeld aan een klant of project.",
        hours: 20,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 51),
      },
      {
        id: 6,
        title: "Chatten",
        description: "De verschillende gebruikers kunnen via de app gesprekken met elkaar voeren en daar ook bestanden en foto's met elkaar delen. ",
        hours: 24,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 6),
      },
      {
        id: 7,
        title: "Documenten delen / beheren",
        description: "Verschillende soorten documenten kunnen gestructureerd bijgehouden en bekeken worden in de app. Ze kunnen worden verdeeld in mappen.",
        hours: 24,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 7),
      },
      {
        id: 8,
        title: "Maps / locatie",
        description: "Er moet gebruik gemaakt worden van de GPS module van het toestellen om bijvoorbeeld afstanden, snelheid of routes te berekenen.",
        hours: 16,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 8),
      },
      {
        id: 9,
        title: "Reviews",
        description: "Gebruikers moeten reviews achter kunnen laten voor ons bedrijf of onze producten of diensten.",
        hours: 14,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 9),
      },
      {
        id: 10,
        title: "Beheer relaties",
        "description": "In de app moeten we klanten en leveranciers en hun contactpersonen kunnen beheren.",
        hours: 20,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 10),
      },
      {
        id: 11,
        title: "Facturatie",
        description: "We moeten via de app de facturatie kunnen doen. We moeten facturen kunnen maken, de betalingen opvolgen herinneringen kunnen sturen.",
        hours: 24,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 11),
      },
      {
        id: 12,
        title: "Producten",
        description: "In de app moeten onze producten en hun specificaties zichtbaar zijn.",
        hours: 20,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 12),
      },
      {
        id: 13,
        title: "Online bestellen",
        description: "Gebruikers van de app moeten onze producten of diensten online kunnen bestellen.",
        hours: 26,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 13),
      },
      {
        id: 14,
        title: "Online betalen",
        description: "Het online betalen van producten, bestellingen of facturen moet mogelijk zijn in de app.",
        hours: 12,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 14),
      },
      {
        id: 15,
        title: "Dashboard",
        description: "Via een dashboard wil ik enkele belangrijke gegevens of grafieken meteen overzichtelijk kunnen zien. ",
        hours: 18,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 15),
      },
      {
        id: 16,
        title: "AI",
        description: "De app moet gebruik maken van AI om bepaalde taken makkelijker te maken.",
        hours: 0,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 16),
      },
      {
        id: 17,
        title: "Camera",
        description: "Er moet gebruik gemaakt worden van de camera van het toestel voor bepaalde taken. ",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 17),
      },
      {
        id: 18,
        title: "Meerdere talen",
        description: "De app moet beschikbaar zijn in meer dan één taal.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        subOptions: this.getFunctionSubOptions().filter((e: SubOption) => e.optionId === 18),
      },

    ];
  }

  getFunctionSubOptions(): SubOption[] {
    return [ //Login
      {
        id: 1,
        optionId: 1,
        title: "E-Mail + Wachtwoord + 2FA",
        description: "Gebruikers kunnen registeren en aanmelden met hun e-mailadres en vervolgens bevestigen met hun smartphone.",
        hours: 14,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 2,
        optionId: 1,
        title: "Google",
        description: "Gebruikers kunnen registeren en aanmelden via hun Google - account.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 3,
        optionId: 1,
        title: "Facebook",
        description: "Gebruikers kunnen registeren en aanmelden via hun Facebook - account.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 4,
        optionId: 1,
        title: "AppleId",
        description: "Gebruikers kunnen registeren en aanmelden via hun Apple - account.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 5,
        optionId: 1,
        title: "Andere",
        description: "Gebruikers kunnen registeren en aanmelden via een ander bestaand platform.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      { //Rollen
        id: 6,
        optionId: 3,
        title: "Eén rol",
        description: "Er is slechts één soort gebruiker, alle gebruikers hebben dezelfde rechten.",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        coefficient: 1,
        singleSelect: true,
      },
      {
        id: 7,
        optionId: 3,
        title: "Meerdere rollen",
        description: "Er zijn verschillende soorten gebruikers die verschillende rechten / mogelijkheden hebben.",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        coefficient: 1.1,
        singleSelect: true,
      },
      { //AI
        id: 8,
        optionId: 16,
        title: "Chatbot",
        description: "Er moet een chatbot aanwezig zijn om eenvoudige vragen te kunnen beantwoorden.",
        hours: 16,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 9,
        optionId: 16,
        title: "Analyse documenten",
        description: "De app moet documenten automatisch kunnen uitlezen en daar de nuttige informatie kunnen uithalen. ",
        hours: 16,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 10,
        optionId: 16,
        title: "Analyse foto's of afbeeldingen",
        description: "De app moet in staat zijn om bepaalde beelden automatisch te kunnen analyseren.",
        hours: 16,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 11,
        optionId: 16,
        title: "Gezichtsherkenning",
        description: "De app moet voorgedefinieerde personen op foto's herkennen.",
        hours: 16,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 12,
        optionId: 16,
        title: "Andere",
        description: "Nog iets anders? Dat bespreken we graag tijdens een persoonlijk gesprek. ",
        hours: 16,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
    ];
  }

  getIntegrationTypes(): Option[] {
    return [
      {
        id: 1,
        title: "Geen",
        description: "Er moet geen integratie zijn met extern platformen.",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Integraties.svg',
        hours: 0,
      },
      {
        id: 2,
        title: "1 integratie",
        description: "Er moet met 1 extern platform geïntegreerd worden.",
        hours: 16,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Integraties.svg',
      },
      {
        id: 3,
        title: "2 integraties",
        description: "Er moet met 2 externe platformen geïntegreerd worden.",
        hours: 32,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Integraties.svg',
      },
      {
        id: 4,
        title: "3 integraties",
        description: "Er moet met 3 externe platformen geïntegreerd worden.",
        hours: 48,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Integraties.svg',
      },
      {
        id: 7,
        title: "Meer dan 3 integraties",
        description: "Er moet met meer dan 3 externe platformen geïntegreerd worden.",
        hours: 64,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Integraties.svg',
      },
    ];
  }

  getOfflineTypes(): Option[] {
    return [
      {
        id: 1,
        title: "Ja, volledig",
        description: "De app moet volledig offline kunnen werken",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Offline-online.svg',
        coefficient: 1.5,
      },
      {
        id: 2,
        title: "Ja, bepaalde functies",
        description: "Bepaalde functies van de app moeten offline kunnen werken",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Offline-online.svg',
        coefficient: 1.25,
      },
      {
        id: 3,
        title: "Nee",
        description: "De app moet niet offline werken",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Offline-online.svg',
        coefficient: 1,
      },
    ];
  }

  getNotificationTypes(): Option[] {
    return [
      {
        id: 1,
        title: "Geen",
        description: "De app moet geen notificaties versturen",
        singleSelect: true,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Notifications.svg',
        hours: 0,
      },
      {
        id: 2,
        title: "Email",
        description: "Ja, de app moet notificaties versturen via e-mail.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Notifications.svg'
      },
      {
        id: 3,
        title: "Push",
        description: "Ja, de app moet push - notificaties kunnen versturen. ",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Notifications.svg'
      },
      {
        id: 4,
        title: "Sms",
        description: "Ja, de app moet notificaties versturen via SMS.",
        hours: 8,
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
        icon: 'Notifications.svg'
      },
    ];
  }

  getContactReasons(): Option[] {
    return [
      {
        id: 1,
        title: "Vergelijkende prijs",
        description:
          "Ik wil een app laten ontwikkelen en wil een vergelijkende prijs",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 2,
        title: "Benieuwd",
        description: "Ik was gewoon benieuwd",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
      {
        id: 3,
        title: "Idee",
        description:
          "Ik heb een idee voor een app maar totaal geen idee wat kost",
        addToCosts: Option.prototype.addToCosts,
        key: Option.prototype.key,
      },
    ];
  }
}
