import React from "react";
import { CalculatorService } from "../../services/calculatorservice";
import { Option } from "../../models/option";
import { ContactService } from "../../services/emailservice";
import SideBar from "../../components/features/Calculator/Sidebar/Sidebar";
import Footer from "../../components/features/Calculator/Footer/Footer";
import { SubOption } from "../../models/suboption";
import { SelectedOption } from "../../models/output/selected-option";
import { SelectedSubOption } from "../../models/output/selected-sub-option";
import { Environment } from "../../Environment";
import { Step } from "../../models/step";
import Modal from "react-responsive-modal";
import { Steps } from "../../utils/steps";
import 'react-responsive-modal/styles.css';
import './Calculator.css';
import Content from "../../components/features/Calculator/Content/Content";

interface CalculatorProps {
  calculatorService: CalculatorService;
  contactService: ContactService;
}

interface CalculatorState {
  formData: any;
  currentStep: number;
  forkedPriceMin: number | null,
  forkedPriceMax: number | null;
  showModal: boolean;
  modalHeader: string;
  saving: boolean,
  submitted: boolean,
  error: string,
  showSideBar: boolean;
}

class Calculator extends React.Component<CalculatorProps, CalculatorState> {
  constructor(props: CalculatorProps) {
    super(props);

    this.state = {
      formData: {
        deviceTypes: [],
        appType: [],
        designType: [],
        functions: [],
        subFunctions: [],
        integrationType: [],
        offlineType: [],
        notificationType: [],
        contactReason: [],
        naam: "",
        email: "",
        phoneNumber: "",
        company: "",
        acceptprivacypolicy: false,
      },
      currentStep: 1,
      forkedPriceMin: null,
      forkedPriceMax: null,
      showModal: false,
      modalHeader: "",
      saving: false,
      submitted: false,
      error: "",
      showSideBar: false,
    };
  }

  private handleInputData = (optionId: number, optionFormControl: string, subOptionId?: number, subOptionFormcontrol?: string) => {
    let fields = { ...this.state.formData };

    const currentStep = this.props.calculatorService.getCurrentStep(this.state.currentStep);

    if (subOptionId) {
      this.selectSubOption(currentStep, optionId, subOptionId, fields, subOptionFormcontrol);
    } else {
      this.selectOption(currentStep, optionId, optionFormControl, fields);
    }

    this.setState({ formData: fields, error: '' });
  };

  private selectOption(currentStep: Step, optionId: number, optionFormControl: string, fields: any) {
    let selectedOptions = fields[optionFormControl];

    let option = currentStep.options!.find(
      (e: Option) => e.id === optionId
    ) as Option;

    let exSelectedOptionIndex = selectedOptions.findIndex((e: Option) => e.id === optionId);
    if (exSelectedOptionIndex > -1) {
      if ((option.subOptions?.length ?? 0) > 0) {
        let subFunctions = fields['subFunctions'];

        let selectedSubFunctionsForOption = subFunctions.filter((e: any) => e.optionId === optionId);
        selectedSubFunctionsForOption.forEach((selected: any) => {
          let selectedSubFunctionsForOptionIndex = subFunctions.findIndex((e: any) => e.id === selected.id);
          if (selectedSubFunctionsForOptionIndex > -1) {
            subFunctions.splice(selectedSubFunctionsForOptionIndex, 1);
          }
        });

        fields['subFunctions'] = subFunctions;
      }
      selectedOptions.splice(exSelectedOptionIndex, 1);
    } else {
      if (!currentStep.multipleOptions || (option && option.singleSelect)) {
        selectedOptions = [];
      }

      if (option && !option.singleSelect) {
        let singleSelectOption = currentStep.options.find((e: Option) => e.singleSelect === true);
        if (singleSelectOption != null) {
          let exSelectedSingleOptionIndex = selectedOptions.findIndex((e: Option) => e.id === singleSelectOption.id);
          if (exSelectedSingleOptionIndex > -1) {
            selectedOptions.splice(exSelectedSingleOptionIndex, 1);
          }
        }

        if ((option.subOptions?.length ?? 0) > 0) {
          // Select first suboption when option has suboptions
          this.selectSubOption(currentStep, optionId, option.subOptions![0].id, fields, 'subFunctions');
        }
      }

      selectedOptions.push(option);
    }

    fields[optionFormControl] = selectedOptions;
  }

  private selectSubOption(currentStep: Step, optionId: number, subOptionId: number, fields: any, subOptionFormcontrol?: string) {
    let selectedSubOptions = fields[subOptionFormcontrol!];

    let option = currentStep.options!.find(
      (e: Option) => e.id === optionId
    ) as Option;

    let subOption = option.subOptions!.find(
      (e: SubOption) => e.id === subOptionId
    ) as SubOption;

    let exSelectedSubOptionIndex = selectedSubOptions.findIndex((e: SubOption) => e.id === subOptionId);
    let exSelectedOptionSubOptions = selectedSubOptions?.filter((e: SubOption) => e.optionId === optionId);
    if (exSelectedSubOptionIndex > -1) {
      if (exSelectedOptionSubOptions.length > 1) {
        selectedSubOptions.splice(exSelectedSubOptionIndex, 1);
      }
    } else {
      let subFunctions = fields['subFunctions'];

      if (subOption.singleSelect) {
        let selectedSubFunctionsForOption = subFunctions.filter((e: any) => e.optionId === optionId);
        selectedSubFunctionsForOption.forEach((selected: any) => {
          let selectedSubFunctionsForOptionIndex = subFunctions.findIndex((e: any) => e.id === selected.id);
          if (selectedSubFunctionsForOptionIndex > -1) {
            subFunctions.splice(selectedSubFunctionsForOptionIndex, 1);
          }
        });
      }

      fields['subFunctions'] = subFunctions;

      selectedSubOptions.push(subOption);
    }

    fields[subOptionFormcontrol!] = selectedSubOptions;
  }

  private handleTextInputData = (input: any, formcontrol: string) => {
    let fields = { ...this.state.formData };

    fields[formcontrol] = input;

    this.setState({ formData: fields, error: '' });
  }

  private submitForm = () => {
    if (this.handleValidation()) {
      this.calculatePrice();
    }
  };

  private handleValidation() {
    let errorList = [];
    let formIsValid = true;

    if (this.state.formData['deviceTypes'] === null || this.state.formData['deviceTypes'].length === 0) {
      errorList.push("Selecteer 1 of meerdere toestellen.");
      formIsValid = false;
    }

    if (this.state.formData['appType'] === null || this.state.formData['appType'].length === 0) {
      errorList.push('Selecteer een type.');
      formIsValid = false;
    }

    if (this.state.formData['designType'] === null || this.state.formData['designType'].length === 0) {
      errorList.push('Selecteer een design.');
      formIsValid = false;
    }

    if (this.state.formData['functions'] === null || this.state.formData['functions'].length === 0) {
      errorList.push('Selecteer 1 of meerdere functies.');
      formIsValid = false;
    }

    if (
      this.state.formData['integrationType'] === null ||
      this.state.formData['integrationType'].length === 0
    ) {
      errorList.push('Selecteer een type integratie.');
      formIsValid = false;
    }

    if (this.state.formData['offlineType'] === null || this.state.formData['offlineType'].length === 0) {
      errorList.push('Selecteer een optie offline.');
      formIsValid = false;
    }

    if (
      this.state.formData['notificationType'] === null ||
      this.state.formData['notificationType'].length === 0
    ) {
      errorList.push('Selecteer een optie notificatie.');
      formIsValid = false;
    }

    if (
      this.state.formData['contactReason'] === null ||
      this.state.formData['contactReason'].length === 0
    ) {
      errorList.push('Selecteer een keuze waarom wenst u een prijs aan te vragen.');
      formIsValid = false;
    }

    if (!this.state.formData['naam']) {
      errorList.push('Naam is verplicht.');
      formIsValid = false;
    }

    if (!this.state.formData['email']) {
      errorList.push('E-mail is verplicht.');
      formIsValid = false;
    }

    if (!this.state.formData['acceptprivacypolicy']) {
      errorList.push('Ik ga akkoord met de privacy policy is verplicht.');
      formIsValid = false;
    }

    if (!formIsValid) {
      this.setState({ error: errorList.join("<br/>"), showModal: true, modalHeader: "Gegevens" });
    }

    return formIsValid;
  }

  private calculatePrice() {
    let costs = { totalHours: 8, coefficient: 1 };

    var deviceTypeCoef = 0.9
    this.state.formData['deviceTypes'].forEach((deviceType: Option) => {
      deviceTypeCoef+=0.1;
    });

    costs.coefficient*= deviceTypeCoef;


    this.state.formData['appType'].forEach((appType: Option) => {
      appType.addToCosts(costs);
    });

    this.state.formData['designType'].forEach((designType: Option) => {
      designType.addToCosts(costs);
    });

    this.state.formData['functions'].forEach((func: Option) => {
      func.addToCosts(costs);
    });

    this.state.formData['subFunctions'].forEach((subFunction: SubOption) => {
      subFunction.addToCosts(costs);
    });

    this.state.formData['integrationType'].forEach((integrationType: Option) => {
      integrationType.addToCosts(costs);
    });

    this.state.formData['offlineType'].forEach((offlineType: Option) => {
      offlineType.addToCosts(costs);
    });

    this.state.formData['notificationType'].forEach((notificationType: Option) => {
      notificationType.addToCosts(costs);
    });

    const totalPrice = (costs.totalHours * 80) * costs.coefficient;
    const forkedPriceMin = totalPrice * 0.85;
    const forkedPriceMax = totalPrice * 1.15;

    this.setState({ forkedPriceMin, forkedPriceMax }, () => {
      let data = {
        toestellen: this.getSelectedOptions(this.state.formData['deviceTypes']),
        type: this.getSelectedOptions(this.state.formData['appType']),
        design: this.getSelectedOptions(this.state.formData['designType']),
        functies: this.getSelectedOptions(this.state.formData['functions']),
        subfuncties: this.getSelectedSubOptions(this.state.formData['subFunctions']),
        integraties: this.getSelectedOptions(this.state.formData['integrationType']),
        offline: this.getSelectedOptions(this.state.formData['offlineType']),
        notificatie: this.getSelectedOptions(this.state.formData['notificationType']),
        waarom: this.getSelectedOptions(this.state.formData['contactReason']),
        naam: this.state.formData['naam'],
        email: this.state.formData['email'],
        telefoon: this.state.formData['phoneNumber'],
        bedrijf: this.state.formData['company'],
        privacyPolicy: this.state.formData['acceptprivacypolicy'],
        totaalPrijs: totalPrice,
        minPrijs: forkedPriceMin,
        maxPrijs: forkedPriceMax
      };

      this.sendContact(data);
    });
  }

  private startNewRequest = () => {
    window.location.reload();
  }

  private editCurrentRequest = () => {
    this.setState({ submitted: false }, () => this.goToStep(Steps.Toestellen));
  }

  private getSelectedOptions(options: Option[]) {
    return options?.map((e: Option) => {
      let selectedOption = new SelectedOption();
      selectedOption.id = e.id;
      selectedOption.description = e.title ?? '';

      return selectedOption;
    });
  }

  private getSelectedSubOptions(subOptions: SubOption[]) {
    return subOptions?.map((e: SubOption) => {
      let selectedOption = new SelectedSubOption();
      selectedOption.id = e.id;
      selectedOption.description = e.title;
      selectedOption.optionId = e.optionId;

      return selectedOption;
    });
  }

  private sendContact = (data: any) => {
    this.setState({ saving: true });

    fetch(`${Environment.ApiUrl}/api/contact`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.toString());
        }

        return response.json();
      })
      .then((result) => {
        if (result) {
          this.setState({ submitted: true, saving: false });
        }
      })
      .catch((err) => {
        this.setState({
          submitted: false,
          saving: false,
          error: 'Er liep iets mis, gelieve later opnieuw te proberen',
          showModal: true,
          modalHeader: 'Foutmelding',
        });
      });
  };

  private goToStep(step: number | null) {
    if (!this.state.submitted) {
      if (step) {
        // Scroll to the top
        window.scrollTo({ top: 0 });

        // Wait for the scroll to finish before updating state
        const checkScrollComplete = () => {
          if (window.scrollY === 0) {
            // Scrolling has finished, update state
            if (step < this.state.currentStep || this.canEnableStep(step)) {
              this.setState({ currentStep: step, error: '' });
            }
          } else {
            // Check again on the next frame
            requestAnimationFrame(checkScrollComplete);
          }
        };

        // Start checking when the scroll is finished
        requestAnimationFrame(checkScrollComplete);
      }
    }
  }

  private canEnableStep(step: number): boolean {
    let fields = { ...this.state.formData };

    const previousSteps = this.props.calculatorService.getPreviousSteps(step);

    let isValid = true;
    previousSteps.forEach((step: Step) => {
      if (isValid) {
        let selectedOptions = fields[step.field];

        if (selectedOptions === null || selectedOptions.length === 0) {
          isValid = false;
        } else {
          selectedOptions.forEach((selectedOption: any) => {
            let option = step.options.find((e: Option) => e.id === selectedOption.id);
            if (option && option.subOptions && option.subOptions.length > 0) {
              let subFunctions = fields['subFunctions'];
              if (subFunctions && subFunctions.findIndex((e: any) => e.optionId === option.id) === -1) {
                isValid = false;
              }
            }
          });
        }
      }
    });

    if (!isValid) {
      let error = 'Gelieve een optie te selecteren.';
      let title = '';
      if (step === Steps.Type) {
        error = 'Gelieve één of meerdere toestellen aan te duiden.';
        title = 'Toestellen';
      } else if (step === Steps.Design) {
        error = 'Gelieve een type aan te duiden.';
        title = 'Type';

      } else if (step === Steps.Functies) {
        error = 'Gelieve een design aan te duiden.';
        title = 'Design';

      } else if (step === Steps.Integraties) {
        error = 'Gelieve één of meerdere (sub)functies aan te duiden.';
        title = 'Functies';

      } else if (step === Steps.Offline) {
        error = 'Gelieve aan te duiden hoeveel integraties er nodig zijn.'
        title = 'Integraties';

      } else if (step === Steps.Notificaties) {
        error = 'Gelieve aan te duiden of de app offline moet werken.';
        title = 'Offline';

      } else if (step === Steps.Gegevens) {
        title = 'Notificaties';

        error = 'Gelieve aan te duiden of de app met notificaties moet werken.'
      }

      this.setState({ error: error, showModal: true, modalHeader: title });

      return false;
    } else {
      this.setState({ error: '' });

      return true;
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  toggleSideBar = () => {
    const showSideBar = this.state.showSideBar;

    this.setState({ showSideBar: !showSideBar });
  }

  render() {
    const { formData } = this.state;

    const steps = this.props.calculatorService.getSteps();
    const currentStep = this.props.calculatorService.getCurrentStep(this.state.currentStep);
    const prevStep = this.props.calculatorService.getPreviousStep(this.state.currentStep);
    const nextStep = this.props.calculatorService.getNextStep(this.state.currentStep);

    return (
      <>
        <Modal
          open={this.state.showModal}
          showCloseIcon={false}
          onClose={this.closeModal}
          center
          classNames={{
            modal: 'customModal',
          }}>
          <div className="modal-content">
            <div className="modal-body pt-0 py-4">
              <div className="row">
                <div className="col-2 d-flex justify-content-center">
                  <div className="error-icon">
                    <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                  </div>
                </div>
                <div className="col-10 ps-0">
                  <h4 className="mb-2 mt-1">{this.state.modalHeader}</h4>
                  <div className="mt-2">
                    <span dangerouslySetInnerHTML={{ __html: this.state.error }}></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary-outline py-2" onClick={this.closeModal}>Sluiten</button>
            </div>
          </div>
        </Modal >

        <button className={`btn-toggle d-lg-none ${this.state.showSideBar ? 'active' : ''}`} onClick={this.toggleSideBar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
            />
          </svg>
        </button>

        <SideBar
          steps={steps}
          stepNumber={this.state.currentStep}
          showSideBar={this.state.showSideBar}
          goToStep={(step: number) => {
            this.goToStep(step)
          }}
        />

        <Content
          formData={formData}
          submitting={this.state.saving}
          submitted={this.state.submitted}
          forkedPriceMin={this.state.forkedPriceMin}
          forkedPriceMax={this.state.forkedPriceMax}
          step={currentStep}
          handleChange={this.handleInputData}
          handleTextInputChange={this.handleTextInputData}
          onSubmit={this.submitForm}
          onStartNewRequest={this.startNewRequest}
          onEditCurrentRequest={this.editCurrentRequest}
        />

        {!this.state.submitted && (
          <Footer
            currentStep={currentStep}
            formData={formData}
            submitting={this.state.saving}
            prevStep={prevStep}
            nextStep={nextStep}
            goToStep={(step: number | null) => {
              this.goToStep(step)
            }}
          />
        )}
      </>
    );
  }
}

export default Calculator;
