import React from "react";
import { Step } from "../../../../models/step";
import StepItem from "../../StepItem/StepItem";

interface ContentProps {
    formData: any;
    step: Step;
    submitting: boolean;
    submitted: boolean;
    forkedPriceMin: any;
    forkedPriceMax: any;
    handleChange: (optionId: number, optionField: string, subOptionId?: number, subOptionField?: string) => void;
    handleTextInputChange: (value: string, field: string) => void;
    onSubmit: () => void;
    onStartNewRequest: () => void;
    onEditCurrentRequest: () => void;
}

interface ContentState {
}

class Content extends React.Component<ContentProps, ContentState> {
    render(): React.ReactNode {
        const isSubmitted = this.props.submitted;

        return (
            <>
                <div className="content-area">
                    <div className="container pb-64">
                        <div className="intro mb-56 pb-md-0 pb-3">
                            <div className="intro-header mb-56 d-flex align-items-center">
                                <div className="intro-header-icon">
                                    <img src={require(`../../../../assets/images/${this.props.step.icon}`).default} alt="icon" />
                                </div>
                                <div className="flex-grow-1">
                                    <h6>STAP {this.props.step.number}</h6>
                                    <h4>{this.props.step.title}</h4>
                                </div>
                            </div>

                            {!isSubmitted && (
                                <p dangerouslySetInnerHTML={{ __html: this.props.step.description}}>
                                 
                                
                                </p>
                            )}
                        </div>

                        <StepItem
                            step={this.props.step}
                            formData={this.props.formData}
                            forkedPriceMin={this.props.forkedPriceMin}
                            forkedPriceMax={this.props.forkedPriceMax}
                            submitted={this.props.submitted}
                            submitting={this.props.submitting}
                            onSelect={(optionId: number, optionField: string, subOptionId?: number, subOptionField?: string) => {
                                this.props.handleChange(optionId, optionField, subOptionId, subOptionField);
                            }}
                            onTextInputChange={(value: string, field: string) => {
                                this.props.handleTextInputChange(value, field);
                            }}
                            onSubmit={() => {
                                this.props.onSubmit()
                            }}
                            onStartNewRequest={() => {
                                this.props.onStartNewRequest()
                            }}
                            onEditCurrentRequest={() => {
                                this.props.onEditCurrentRequest()
                            }}
                        >
                        </StepItem>
                    </div>
                </div>
            </>
        )
    }
}

export default Content;