import React from "react";
import arrowBack from '../../../../assets/images/Arrowback.svg';
import arrowRight from '../../../../assets/images/ArrowRight.svg';
import { Step } from "../../../../models/step";

interface FooterProps {
    currentStep: Step;
    formData: any;
    submitting: boolean;
    nextStep: Step | null;
    prevStep: Step | null;
    goToStep: (step: number | null) => void;
}

interface FooterState {

}

class Footer extends React.Component<FooterProps, FooterState> {
    private goToStep(e: any, stepNumber: number) {
        e.preventDefault();

        this.props.goToStep(stepNumber)
    }

    render(): React.ReactNode {
        return (
            <>
                <footer className="footer">
                    <div className="row g-0">
                        <div className="col-6 d-flex align-items-center justify-content-between">
                            {this.props.prevStep && (
                                <>
                                    {this.props.submitting
                                        ?
                                        <a className="btn-link btn-link-icon pager notClickable">
                                            <img src={arrowBack} alt="icon" />
                                            <span>Vorige stap</span>
                                        </a>
                                        : <a href="/#" onClick={(e) => this.goToStep(e, this.props.prevStep!.number)} className="btn-link btn-link-icon pager">
                                            <img src={arrowBack} alt="icon" />
                                            <span>Vorige stap</span>
                                        </a>
                                    }

                                    <a href="/#" onClick={(e) => this.goToStep(e, this.props.prevStep!.number)} className="nav-link ms-auto">
                                        {this.props.prevStep!.title}
                                        <span>
                                            <img src={require(`../../../../assets/images/${this.props.prevStep!.icon}`).default} alt="Design" />
                                        </span>
                                    </a>
                                </>
                            )}
                        </div>

                        <div className="col-6 d-flex align-items-center justify-content-between">
                            {this.props.nextStep && (
                                <>
                                    <a href="/#" onClick={(e) => this.goToStep(e, this.props.nextStep!.number)} className="nav-link me-auto">
                                        <span>
                                            <img src={require(`../../../../assets/images/${this.props.nextStep!.icon}`).default} alt="Type" />
                                        </span>
                                        {this.props.nextStep!.title}
                                    </a>

                                    {this.props.submitting
                                        ? <a className="btn-link btn-link-icon pager notClickable">
                                            <span>Volgende stap</span>
                                            <img src={arrowRight} alt="icon" />
                                        </a>
                                        : <a href="/#" onClick={(e) => this.goToStep(e, this.props.nextStep!.number)} className="btn-link btn-link-icon pager">
                                            <span>Volgende stap</span>
                                            <img src={arrowRight} alt="icon" />
                                        </a>}
                                </>
                            )}
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}

export default Footer;