import { SubOption } from "./suboption";

export class Option {
  id!: number;
  title!: string;
  description?: string;
  subOptions?: SubOption[];
  hours?: number;
  icon?: string;
  singleSelect?: boolean;
  coefficient?: number;

  constructor(data?: Option) {
    if (data) {
      Object.assign(this, data);

      if (data.subOptions) {
        this.subOptions = data.subOptions.map((e) => new SubOption(e));
      }
    }
  }

  addToCosts(costs: any) {
    if (this.hours) {
      costs.totalHours += this.hours;
    } else if (this.coefficient) {
      costs.coefficient *= this.coefficient;
    }
  }

  key(): string {
    return `${this.title?.slice(0, 2).toLowerCase()}_${this.id?.toString()}`;
  }
}
