import { Option } from "./option";

export class Step {
  number!: number;
  title!: string;
  description!: string;
  icon!: string;
  options!: Option[];
  multipleOptions!: boolean;
  field!: string;
  templateType!: number;

  constructor(data?: Step) {
    if (data) {
      Object.assign(this, data);

      if (data.options) {
        this.options = data.options.map((e) => new Option(e));
      }
    } else {
      this.multipleOptions = false;
    }
  }
}
