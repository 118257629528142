import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Calculator from "../Calculator/Calculator";
import { CalculatorService } from "../../services/calculatorservice";
import { ContactService } from "../../services/emailservice";
import Home from "../Home/Home";

class App extends Component {
  render() {
    const calculatorService = new CalculatorService();
    const contactService = new ContactService();

    return (
      <BrowserRouter>
        <div>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                />
              }
            ></Route>
            <Route
              path="/calculator"
              element={
                <Calculator
                  calculatorService={calculatorService}
                  contactService={contactService}
                />
              }
            ></Route>
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
