export class SelectedSubOption {
  id!: number;
  description?: string;
  optionId!: number;

  constructor(data?: SelectedSubOption) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
