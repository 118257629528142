export class SubOption {
  id!: number;
  optionId!: number;
  title!: string;
  description?: string;
  hours?: number;
  coefficient?: number;
  singleSelect?: boolean;

  constructor(data?: SubOption) {
    if (data) {
      Object.assign(this, data);
    }
  }

  addToCosts(costs: any) {
    if (this.hours) {
      costs.totalHours += this.hours;
    } else if (this.coefficient) {
      costs.coefficient *= this.coefficient;
    }
  }

  key(): string {
    return `${this.title?.slice(0, 2).toLowerCase()}_${this.id?.toString()}`;
  }
}
