import React from "react";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import App from "./pages/App/App";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/css/main.css";
import "./assets/styles/css/custom.css";
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
