export class SelectedOption {
  id!: number;
  description!: string;

  constructor(data?: SelectedOption) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
