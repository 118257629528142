import React from "react";
import logo from "../../assets/images/logo.svg";
import frameCards from "../../assets/images/frame-cards.svg";

interface HomeProps {

}

class Home extends React.Component<HomeProps, any> {
    render(): React.ReactNode {
        return (
            <>
                <div className="landing-view">
                    <img src={frameCards} className="img-fluid mx-auto mb-auto" alt="cards" />

                    <div className="sidebar d-flex sidebar-landing">
                        <div className="mb-48">
                            <img src={logo} alt="logo" />
                        </div>

                        <div className="sidebar-content d-flex flex-column align-items-start">
                            <div className="mb-64">
                                <h1>Wat kost <br />een app?</h1>
                                <p>Wil je weten wat een app ongeveer kost? Druk hieronder op 'Start calculator' en onze digitale calculator helpt je graag verder. </p>
                            </div>
                            <a href="calculator" className="btn btn-blue mb-32">Start calculator</a>
                            <a href="https://www.codecraft.be" target="_blank" rel="noreferrer" className="btn-link">Meer weten over codecraft</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;