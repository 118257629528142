import React from "react";
import { Step } from "../../../../models/step";
import logo from "../../../../assets/images/logo.svg";

interface SideBarProps {
    steps: Step[];
    stepNumber: number;
    showSideBar: boolean;
    goToStep: (stepNumber: number) => void;
}

interface SideBarState {

}

class SideBar extends React.Component<SideBarProps, SideBarState> {
    render(): React.ReactNode {
        return (
            <>
                <div className={`sidebar ${this.props.showSideBar ? 'active' : ''}`}>
                    <div className="w-100">
                        <div className="mb-96">
                            <img src={logo} alt="logo" />
                        </div>

                        <ul className="sidebar-links list-unstyled m-0 p-0">
                            {this.props.steps.map((item: Step, i: number) => {
                                return (
                                    <li key={i} className={this.props.stepNumber === item.number ? 'active' : ''}>
                                        <a href="/#" onClick={(e) => { e.preventDefault(); this.props.goToStep(item.number) }} className="nav-link">
                                            <span>
                                                <img src={require(`../../../../assets/images/${item.icon}`).default} alt={item.title} />
                                            </span>
                                            {item.title}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <a href="https://www.codecraft.be/contact" target="_blank" rel="noreferrer" className="btn btn-blue-outline px-0 w-100">Contacteer ons</a>
                </div >
            </>
        )
    }
}

export default SideBar;